export default {
    maxInvestAmount: 'Maximum Amount',
    minInvestAmount: 'Minimum Amount',
    cumulativeInvestment: 'Cumulative Investment',
    cumulativeIncome: 'Cumulative Income',
    locked: 'LOCKED',
    unlocked: 'UNLOCKED',
    systemInfo: 'Fund custody and profit sharing system',
    btnAll: 'all',
    availableBalance: 'Balance',
    currentPrice: 'price',
    investMembers: 'Invest members',
    investCounts: 'Invest times',
    approve: 'APPROVE',
    openAccount: 'CREATE',
    investDetail: 'Investment Detail',
    investmentIncome: 'Investment Income',
    extractedIncome: 'Extracted Income',
    investmentAmount: 'Investment Amount',
    investmentDuration: 'Investment Duration',
    days: 'Days',
    investmentTime: 'Create Time',
    expireTime: 'Expire Time',
    btnExtract: 'EXTRACT',
    bindLeader:'Bind Leader',
    bindPlaceholder:'Please enter leader address',
    insufficientBalance:'Insufficient balance',
    maxAmount:'Max',
    limitAmount:'Less than the minimum investment amount',
    limitApprove:'The current authorized transfer balance is insufficient. Do you want to continue authorizing?',
    tradeCancel:'CANCEL',
    investSuccess:'SUCCESS',
    investCancel:'CANCELED',
    investFail:'FAILED',
    walletZero:'Insufficient balance',
    approveSuccess:'SUCCESS',
    approveReject:'REJECTED',
    approveFail:'FAILED',
    approving:'approving...',
    enterAddress:'Please enter the correct wallet address',
    binding:'binding...',
    bindSuccess:'SUCCESS',
    bindFail:'FAILED',
}
