import Web3 from "web3";
import {tokenContractABI} from "@/assets/chain/contractDetails";

class WalletUtils {
  constructor() {
    this.web3Instance = null;
    this.account = "";
  }

  // 检测是否为区块链浏览器
  isBlockchainBrowser() {
    return typeof window.ethereum !== "undefined" || typeof window.BinanceChain !== "undefined";
  }

  // 获取区块链类型
  getBlockchainType() {
    if (typeof window.ethereum !== "undefined") {
      return "Ethereum";
    } else if (typeof window.BinanceChain !== "undefined") {
      return "Binance Smart Chain";
    } else {
      return "Unknown";
    }
  }

  // 获取钱包地址
  async getWalletAddress() {
    try {
      if (typeof window.ethereum !== "undefined") {
        this.web3Instance = new Web3(window.ethereum);
        await window.ethereum.enable();
      } else if (typeof window.BinanceChain !== "undefined") {
        this.web3Instance = new Web3(window.BinanceChain);
        await window.BinanceChain.enable();
      } else {
        console.log("非区块链浏览器环境");
        return;
      }
      const accounts = await this.web3Instance.eth.getAccounts();
      this.account = accounts[0];
    } catch (error) {
      console.error("用户未授权");
      return;
    }
    return this.account;
  }

  async getTokenBalance(tokenAddress, walletAddress) {
    try {
      if (typeof window.ethereum !== "undefined") {
        this.web3Instance = new Web3(window.ethereum);
        await window.ethereum.enable();
      } else if (typeof window.BinanceChain !== "undefined") {
        this.web3Instance = new Web3(window.BinanceChain);
        await window.BinanceChain.enable();
      } else {
        console.log("非区块链浏览器环境");
        return 0;
      }
      const tokenContract = new this.web3Instance.eth.Contract(tokenContractABI, tokenAddress);
      const balance = await tokenContract.methods.balanceOf(walletAddress).call();
      // const decimals = await tokenContract.methods.decimals().call();
      return this.web3Instance.utils.fromWei(balance, 'ether');
    } catch (error) {
      console.error("获取代币余额失败", error);
      return 0;
    }
  }
}

export default WalletUtils;
