import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {Button, Icon, Toast, Dialog, Field, CellGroup, Popup, Divider, Popover} from 'vant';
import 'vant/lib/index.css';
import {createI18n} from "vue-i18n";

import cn from "./assets/locales/cn";
import en from "./assets/locales/en";

const language = {
    en: en,
    cn: cn
}

const i18n = createI18n({
    locale: "en",
    messages: language
});


createApp(App)
    .use(store)
    .use(router)
    .use(Button)
    .use(Icon)
    .use(Toast)
    .use(Dialog)
    .use(Field)
    .use(CellGroup)
    .use(Popup)
    .use(i18n)
    .use(Divider)
    .use(Popover)
    .mount('#app')
