<template>
  <div class="home">
    <div class="nav">
      <nav-bar :wallet-address="walletAddress"/>
    </div>
    <div class="main">
      <div class="info">
        <div class="info-item">
          <span class="amount">{{
              (order.totalInvested * 3 - order.totalProfit + order.withdrawableProfit).toFixed(2)
            }}</span>
          <span class="subtitle">剩余可提$</span>
          <div class="deposit-box">
            <span class="deposit-amount">{{ order.pendingProfit.toFixed(2) }}</span>
            <span class="deposit-subtitle">待结算收益$</span>
          </div>
        </div>
        <div class="info-item">
          <span class="amount">{{ order.totalProfit.toFixed(2) }}</span>
          <span class="subtitle">累计收益$</span>
          <div class="deposit-box">
            <span class="deposit-amount">{{ order.withdrawableProfit.toFixed(2) }}</span>
            <span class="deposit-subtitle">已结算收益$</span>
          </div>
        </div>
      </div>
      <div class="team-info">
        <span class="team-item">直推人数：{{ order.directReferrals }}</span>
        <span class="team-item">团队业绩：{{ order.teamInvestment.toFixed(0) }}</span>
      </div>

      <div class="invest">
        <div class="balance">
          <span>Your wallet balance is: {{ Number(walletTokenBalance).toFixed(2) }} USDT</span>
        </div>
        <input class="invest-input" v-model="investAmount" placeholder="Enter invest quantity">
        <div class="quick-button">
          <div class="btn-item" @click="handleQuickInvest(100)">100</div>
          <div class="btn-item" @click="handleQuickInvest(500)">500</div>
          <div class="btn-item" @click="handleQuickInvest(1000)">1000</div>
          <div class="btn-item" @click="handleQuickInvest(3000)">3000</div>
          <div class="btn-item" @click="handleQuickInvest(5000)">5000</div>
        </div>
        <div class="invest-button" v-if="allowance < 5000" @click="approveTokens">授权</div>
        <div class="invest-button" v-else @click="invest">投资</div>
      </div>
      <div class="project-info">
        <div class="title">Kepler</div>
        <div class="content">
          致力於提供全面的加密貨幣市場數據分析，幫助投資者、研究人員和機構瞭解數字資產市場的動態。Kepler
          提供的數據涵蓋了多個加密貨幣網路，包括比特幣、以太坊和其他主要加密貨幣。分析工具和報告涵蓋了市場趨勢、交易活動
          、區塊鏈指標等方面，為市場參與者提供洞察力和決策支援.
        </div>
        <div class="level-box">
          <div class="level-item">
            <span>LEVEL.01</span>
            <span>5个有效账户</span>
            <span>团队奖励 5%</span>
          </div>
          <van-divider/>
          <div class="level-item">
            <span>LEVEL.02</span>
            <span>5万团队业绩</span>
            <span>团队奖励 10%</span>
          </div>
          <van-divider/>
          <div class="level-item">
            <span>LEVEL.03</span>
            <span>20万团队业绩</span>
            <span>团队奖励 15%</span>
          </div>
          <van-divider/>
          <div class="level-item">
            <span>LEVEL.04</span>
            <span>50万团队业绩</span>
            <span>团队奖励 20%</span>
          </div>
          <van-divider/>
          <div class="level-item">
            <span>LEVEL.05</span>
            <span>100万团队业绩</span>
            <span>团队奖励 25%</span>
          </div>
        </div>
      </div>
    </div>
    <van-dialog
        v-model:show="bindDialogShow"
        title="绑定领导人"
        show-cancel-button
        @confirm="handleBindConfirm">
      <van-cell-group inset>
        <van-field
            v-model="leaderAddress"
            rows="1"
            autosize
            type="textarea"
            placeholder="请输入领导人钱包地址"
        />
      </van-cell-group>
    </van-dialog>
  </div>
</template>
<script setup>
import NavBar from "@/components/NavBar.vue";

import Web3 from "web3";
import {
  stakingContractABI,
  stakingContractAddress,
  tokenContractABI,
  tokenContractAddress
} from "@/assets/chain/contractDetails";
import WalletUtils from "@/utils/WalletUtils";
import {closeToast, showFailToast, showLoadingToast, showSuccessToast} from "vant";
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";

const route = useRoute();

const walletAddress = ref(undefined);
const walletTokenBalance = ref(0);

const allowance = ref(0);
const allowanceLoading = ref(false);

let stakingContractInstance = undefined;
let tokenContractInstance = undefined;
let web3 = undefined;
const initWeb3 = (async () => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
    web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    stakingContractInstance = new window.web3.eth.Contract(stakingContractABI, stakingContractAddress);
    tokenContractInstance = new window.web3.eth.Contract(tokenContractABI, tokenContractAddress);
  } else {
    console.error("Please install MetaMask!")
  }
});

const getAddress = (async () => {
  const walletUtils = new WalletUtils();
  if (walletUtils.isBlockchainBrowser()) {
    walletAddress.value = await walletUtils.getWalletAddress();
  }
});

const getTokenBalance = (async () => {
  const walletUtils = new WalletUtils();
  if (walletUtils.isBlockchainBrowser()) {
    const balance = await walletUtils.getTokenBalance(tokenContractAddress, walletAddress.value);
    walletTokenBalance.value = Number(balance);
  } else {
    console.error("获取钱包余额失败");
  }
});

const order = ref({
  totalInvested: 0,
  totalProfit: 0,
  pendingProfit: 0,
  withdrawableProfit: 0,
  teamInvestment: 0,
  directReferrals: 0
})
const getOrder = async () => {
  try {
    const info = await stakingContractInstance.methods.users(walletAddress.value).call();
    order.value.totalInvested = Number(web3.utils.fromWei(Number(info.totalInvested), 'ether'));
    order.value.totalProfit = Number(web3.utils.fromWei(Number(info.totalProfit), 'ether'));
    order.value.pendingProfit = Number(web3.utils.fromWei(Number(info.pendingProfit), 'ether'));
    order.value.withdrawableProfit = Number(web3.utils.fromWei(Number(info.withdrawableProfit), 'ether'));
    order.value.teamInvestment = Number(web3.utils.fromWei(Number(info.teamInvestment), 'ether'));
    order.value.directReferrals = Number(info.directReferrals);
  } catch (error) {
    console.log(error);
  }
}

const updateAllowance = (async () => {
  const res = await tokenContractInstance.methods
      .allowance(walletAddress.value, stakingContractInstance.options.address)
      .call();
  allowance.value = web3.utils.fromWei(res, "ether");
});

const approveTokens = (async () => {
  try {
    allowanceLoading.value = true;
    await tokenContractInstance.methods
        .approve(stakingContractInstance.options.address, web3.utils.toWei('30000', "ether"))
        .send({from: walletAddress.value});
    await updateAllowance();
    allowanceLoading.value = false;
    showSuccessToast("授权成功");
  } catch (error) {
    allowanceLoading.value = false;
    if (error.code === 4001) {
      showFailToast("拒绝授权");
      return;
    }
    if (error.code === -32603) {
      showFailToast("授权失败");
      return;
    }
    showFailToast("授权失败");
  }
})

const leaderAddress = ref(undefined);
const bindDialogShow = ref(false);
const checkBind = (async () => {
  const inviteAddress = route.query.inviteAddress;
  if (inviteAddress) {
    leaderAddress.value = inviteAddress;
    const res = await stakingContractInstance.methods.isUserBound(walletAddress.value).call();
    if (res) {
      return;
    }
    bindDialogShow.value = true;
  }
});

const handleBindConfirm = (async () => {
  try {
    if (!leaderAddress.value && !web3.utils.isAddress(leaderAddress.value)) {
      showFailToast("请输入正确的钱包地址");
      return;
    }
    showLoadingToast({
      message: '绑定中...',
      forbidClick: true,
      loadingType: 'spinner',
      duration: 0
    });
    await stakingContractInstance.methods.bindReferrer(leaderAddress.value).send({from: walletAddress.value});
    closeToast();
    showSuccessToast("绑定成功");
  } catch (error) {
    closeToast();
    if (error.code === 4001) {
      showFailToast("取消绑定");
      return;
    }
    if (error.code === -32603) {
      showFailToast("绑定失败");
      return;
    }
    showFailToast("绑定失败");
  }
})

const investAmount = ref(undefined)
const invest = async () => {
  try {
    if (investAmount.value && investAmount.value >= 100) {
      const isBound = await stakingContractInstance.methods.isUserBound(walletAddress.value).call()
      if (!isBound) {
        bindDialogShow.value = true;
        return;
      }
      await stakingContractInstance.methods.invest(web3.utils.toWei(investAmount, 'ether')).send({from: walletAddress.value});
      showSuccessToast("投资成功");
    } else {
      showFailToast("请输入正确定投资数量");
    }
  } catch (error) {
    console.log(error);
    showFailToast("投资失败");
  }
}

const handleQuickInvest = (amount) => {
  investAmount.value = amount;
}

onMounted(async () => {
  await initWeb3();
  await getAddress();
  await getTokenBalance();
  await updateAllowance();
  await checkBind();
  await getOrder();
})

</script>
<style scoped lang="less">
.home {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .nav {
    flex: 0 0 auto;
  }

  .main {
    flex: 1 0 0;
    padding: 10px 18px 20px 18px;
    overflow: auto;

    .info {
      width: 100%;
      padding: 10px 20px 20px 20px;
      background-color: #3678d8;
      display: flex;
      flex-direction: row;
      border-radius: 12px;
      box-sizing: border-box;
      justify-content: space-between;

      .info-item {
        width: 47%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .amount {
          padding: 10px 0 0 0;
          box-sizing: border-box;
          font-size: 30px;
          color: white;
          font-weight: bold;
        }

        .subtitle {
          padding: 5px;
          box-sizing: border-box;
          font-size: 16px;
          color: white;
          font-weight: bold;
        }

        .deposit-box {
          width: 100%;
          padding: 10px 0;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          border-radius: 12px;
          align-items: center;
          background-color: rgba(252, 243, 218, 0.56);

          .deposit-amount {
            font-size: 30px;
            color: #3678d8;
            font-weight: bold;
          }

          .deposit-subtitle {
            padding: 5px;
            box-sizing: border-box;
            font-size: 16px;
            color: #4b4b4b;
            font-weight: bold;
          }
        }
      }
    }

    .team-info {
      margin-top: 20px;
      width: 100%;
      padding: 10px 20px 10px 20px;
      border: 1px solid #6dd2ac;
      display: flex;
      flex-direction: row;
      border-radius: 12px;
      box-sizing: border-box;
      justify-content: space-between;

      .team-item {
        color: #6dd2ac;
      }
    }

    .invest {
      margin: 20px 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .balance {
        padding: 10px 0;
        color: gray;
        font-size: 14px;
        box-sizing: border-box;
      }

      .invest-input {
        margin: 5px 0;
        width: 100%;
        height: 40px;
        background: black;
        font-size: 20px;
        color: white;
        padding: 5px;
        box-sizing: border-box;
        border: 1px solid #3678d8;
        border-radius: 6px;
      }

      .quick-button {
        padding: 10px 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .btn-item {
          padding: 10px 0;
          width: 19%;
          background-color: rgba(128, 128, 128, 0.71);
          border-radius: 4px;
          border: 1px solid #9d9d9d;
          color: white;
          box-sizing: border-box;
          align-items: center;
          text-align: center;
        }
      }

      .invest-button {
        margin: 10px 0;
        width: 100%;
        padding: 10px;
        text-align: center;
        color: white;
        font-size: 18px;
        background-color: #4b4b4b;
        border: 1px solid white;
        border-radius: 4px;
        box-sizing: border-box;
      }
    }

    .project-info {
      width: 100%;
      display: flex;
      flex-direction: column;

      .title {
        width: 100%;
        color: white;
        font-weight: bold;
        font-size: 25px;
        text-align: center;
      }

      .content {
        padding: 20px 0;
        width: 100%;
        color: white;
        font-size: 16px;
        box-sizing: border-box;
      }

      .level-box {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        border: 1px solid gray;
        border-radius: 4px;
        display: flex;
        flex-direction: column;

        .level-item {
          width: 100%;
          color: gray;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
