export default {
    maxInvestAmount: '最大额度',
    minInvestAmount: '最小额度',
    cumulativeInvestment: '累计投资',
    cumulativeIncome: '累计收益',
    locked: '待解锁',
    unlocked: '已解锁',
    systemInfo: '资金托管分润系统',
    btnAll: '全部',
    availableBalance: '可用余额',
    currentPrice: '当前价格',
    investMembers: '投资人数',
    investCounts: '投资次数',
    approve: '授权',
    openAccount: '创建账户',
    investDetail: '投资详情',
    investmentIncome: '投资收益',
    extractedIncome: '已提收益',
    investmentAmount: '投资数量',
    investmentDuration: '投资期限',
    days: '天',
    investmentTime: '开始时间',
    expireTime: '到期时间',
    btnExtract: '提取收益',
    bindLeader:'绑定领导人',
    bindPlaceholder:'请输入领导人钱包地址',
    insufficientBalance:'钱包余额不足',
    maxAmount:'最大可输入',
    limitAmount:'小于最小投资数量',
    limitApprove:'当前授权转账余额不足，是否继续授权？',
    tradeCancel:'取消交易',
    investSuccess:'投资成功',
    investCancel:'取消投资',
    investFail:'投资失败',
    walletZero:'钱包余额为0，请先充值！',
    approveSuccess:'授权成功',
    approveReject:'拒绝授权',
    approveFail:'授权失败',
    approving:'授权中...',
    enterAddress:'请输入正确的钱包地址',
    binding:'绑定中...',
    bindSuccess:'绑定成功',
    bindFail:'绑定失败',
}
